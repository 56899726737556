import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useDynamoDB } from '../../DynamoDBContext';
const Clients = () => {
    const dynamoDB = useDynamoDB();
    const [clients, setClients] = useState([]);
    
    const fetchClients = useCallback(async () => {
        const params = { TableName: 'Clients' };
        const data = await dynamoDB.scan(params).promise();
        console.log("Clients data>",data)
        setClients(data.Items);
    }, [dynamoDB]);
    
    useEffect(() => {
        fetchClients();
      }, [fetchClients]);
      
      const [newClient, setNewClient] = useState({ name: '', website: '', email: '' });
      const handleAddClient = async () => {
        const params = {
          TableName: 'Clients',
          Item: { ...newClient, id: Date.now().toString() },
        };
        await dynamoDB.put(params).promise();
        fetchClients();
      };
    //   <div>
    //     <h2>Clients</h2>
    //     <form>
    //       <div className="form-group">
    //         <label>Client Name</label>
    //         <input type="text" placeholder="Enter client name" />
    //       </div>
    //       <div className="form-group">
    //         <label>Client Website</label>
    //         <input type="text" placeholder="Enter client website" />
    //       </div>
    //       <div className="form-group">
    //         <label>Client Email</label>
    //         <input type="email" placeholder="Enter client email" />
    //       </div>
    //       <div className="form-group">
    //         <label>Assign Categories</label>
    //         <input type="text" placeholder="Enter category IDs (comma-separated)" />
    //       </div>
    //       <div className="form-group">
    //         <label>Assign Samples</label>
    //         <input type="text" placeholder="Enter sample IDs (comma-separated)" />
    //       </div>
    //       <button type="submit">Save Client</button>
    //     </form>
    //   </div>
    <div className="clients-content">
                <h3>Manage Clients</h3>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">Client Name</Form.Label>
                    <Col sm="10">
                      <Form.Control type="text" value={newClient.name}
                        onChange={(e) => setNewClient({ ...newClient, name: e.target.value })} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">Client Website</Form.Label>
                    <Col sm="10">
                      <Form.Control type="text" value={newClient.website}
                        onChange={(e) => setNewClient({ ...newClient, website: e.target.value })} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">Client Email</Form.Label>
                    <Col sm="10">
                      <Form.Control type="email" value={newClient.email}
                        onChange={(e) => setNewClient({ ...newClient, email: e.target.value })} />
                    </Col>
                  </Form.Group>
                  <Button onClick={handleAddClient}>Add Client</Button>
                </Form>
    
                <h4>Existing Clients</h4>
                <ul>
                  {clients.map(client => (
                    <li key={client.ClientID}>
                      {client.name} ({client.email}) - {client.website}
                    </li>
                  ))}
                </ul>
              </div>
    };

    export default Clients;