import React, { useRef, useEffect } from 'react';
import "./ImageModal.css";
const ImageModal = ({ imageUrl, imageTitle, onClose }) => {
  console.log(imageUrl, imageTitle)
  const modalRef = useRef();

  useEffect(() => {
    // Function to close the modal when clicking outside of it
    const handleCloseModal = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add a click event listener to the document
    document.addEventListener('mousedown', handleCloseModal);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleCloseModal);
    };
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        
        <div><img src={imageUrl} alt={imageTitle} title={imageTitle} /></div>
        <button className="close-button" onClick={onClose} alt="Close" title="Close">
          {/* Close */}
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
