import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import './fonts.css';
import App from './App';

const rootElement = document.getElementById('root');
const basename = process.env.NODE_ENV === 'production' ? '/DigitalSamplesShowcase/showcase' : './';
createRoot(rootElement).render(
  <Router basename={basename}>
    <App />
  </Router>
);