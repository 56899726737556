// import fetchCategories from './fetchCategories';

// export const getCategories = async () => {
//   const categoriesData = await fetchCategories();
//   return categoriesData;
// };

// export const getCategoryOptions = async (categoryId) => {
//   const categoriesData = await fetchCategories();
//   const category = categoriesData.find((cat) => cat.id === categoryId);
//   return category ? category.options : [];
// };

class CategoryData {
  constructor(dynamoDB) {
    this.dynamoDB = dynamoDB;
  }

  async getCategories() {
    const params = {
      TableName: "Categories",
    };

    try {
      const data = await this.dynamoDB.scan(params).promise();
      data.Items.sort((a, b) => a.sequence - b.sequence);
      return data.Items;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  }

  async getCategoryOptions(categoryId) {
    const params = {
      TableName: "Samples",
      FilterExpression: "CategoryID = :categoryId",
      ExpressionAttributeValues: {
        ":categoryId": categoryId,
      },
    };

    try {
      const data = await this.dynamoDB.scan(params).promise();
      return data.Items;
    } catch (error) {
      console.error("Error fetching category options:", error);
      throw error;
    }
  }
}

export default CategoryData;
