import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Col, Row, Card } from "react-bootstrap";
import ImageSelectorPopup from "./ImageSelectorPopup";
import { useDynamoDB } from "../../DynamoDBContext";

// Import all images from the folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../../images", false, /\.(png|jpe?g|svg)$/)
);

const Samples = () => {
  const dynamoDB = useDynamoDB();
  const [categories, setCategories] = useState([]);
  const [samples, setSamples] = useState([]);
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fetchCategories = useCallback(async () => {
    const params = { TableName: "Categories" };
    const data = await dynamoDB.scan(params).promise();
    data.Items.sort((a, b) => a.sequence - b.sequence);
    console.log("Categories> ",data.Items)
    setCategories(data.Items);
  }, [dynamoDB]);

  const fetchSamples = useCallback(async () => {
    const params = { TableName: "Samples" };
    const data = await dynamoDB.scan(params).promise();
    setSamples(data.Items);
  }, [dynamoDB]);

  useEffect(() => {
    fetchCategories();
    fetchSamples();
  }, [fetchSamples, fetchCategories]);

  const [newSample, setNewSample] = useState({
    SampleID: "",
    title: "",
    open: "inline",
    type: "",
    icon: "",
    url: "",
    CategoryID: "",
  });

  const handleAddOrUpdateSample = async () => {
    const params = {
      TableName: "Samples",
      Item: {
        ...newSample,
        SampleID: newSample.SampleID || Date.now().toString(),
      },
    };
    await dynamoDB.put(params).promise();
    fetchSamples();
    setIsEditing(false);
    setNewSample({
      SampleID: "",
      title: "",
      open: "inline",
      type: "",
      icon: "",
      url: "",
      CategoryID: "",
    });
  };

  const handleCancelSample = () => {
    setIsEditing(false);
    setNewSample({
      SampleID: "",
      title: "",
      open: "inline",
      type: "",
      icon: "",
      url: "",
      CategoryID: "",
    });
  };

  const handleEditSample = (sample) => {
    setNewSample(sample);
    setIsEditing(true);
  };

  const handleDeleteSample = async (SampleID) => {
    const params = {
      TableName: "Samples",
      Key: { SampleID },
    };
    await dynamoDB.delete(params).promise();
    fetchSamples();
  };

  const handleImageSelect = (image) => {
    setNewSample({ ...newSample, icon: image });
    setShowImageSelector(false);
  };

  const onDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  const onDrop = async (e, CategoryID) => {
    const id = e.dataTransfer.getData("id");
    const params = {
      TableName: "Samples",
      Key: { SampleID: id },
      UpdateExpression: "set CategoryID = :CategoryID",
      ExpressionAttributeValues: { ":CategoryID": CategoryID },
    };
    await dynamoDB.update(params).promise();
    fetchSamples();
  };

  const uncategorizedSamples = samples.filter(
    (sample) => !sample.CategoryID || sample.CategoryID === ""
  );











  const samplesList = [
    { "id": 1, "title": "Astronomy Eclipse", "icon": "/images/eclipse.png", "url": "https://dlg.aptaracorp.com/DigitalSamplesShowcase/SamplesShowcase/src/Interactivities/Astronomy/Eclipse/index.html" },
    { "id": 2, "title": "Circular Motion", "icon": "/images/circular.png", "url": "https://dlg.aptaracorp.com/DigitalSamplesShowcase/SamplesShowcase/src/Interactivities/Griffith/CircularMotion/index.html" },
  { "id": 3, "title": "Matching 1", "icon": "/images/02_Interactive_activity_01.png", "url": "https://dlg.aptaracorp.com/DigitalSamplesShowcase/Interactivities/DragDrop/Matching3/" },
  { "id": 4, "title": "Matching 2", "icon": "/images/02_Interactive_activity_02.png", "url": "https://dlg.aptaracorp.com/DigitalSamplesShowcase/Interactivities/DragDrop/Matching5/" }
];


const getExtension = (url) => {
  return url.split('.').pop().toLowerCase();
};

const processedSamples = samplesList.map(sample => {
  return {
    ...sample,
    open: sample.open || "external",
    type: (() => {
      const ext = getExtension(sample.url);
      if (['mp4'].includes(ext)) return 'video';
      if (['png', 'jpg', 'jpeg'].includes(ext)) return 'image';
      if (['html'].includes(ext)) return 'html';
      return 'unknown';
    })(),
    Category: sample.Category || 'DefaultCategory' // Replace 'DefaultCategory' with your desired default category value
  };
});

const addSample = async (sample) => {
  let newSampleObj = {
    SampleID: Date.now().toString(),
    title: sample.title,
    open: sample.open,
    type: sample.type,
    icon: sample.icon,
    url: sample.url,
    CategoryID: "1724002604936",
  };

  


  const params = {
    TableName: 'Samples',
    Item: newSampleObj,
  };

  // await dynamoDB.put(params).promise();

  try {
    await dynamoDB.put(params).promise();
    console.log(`Added sample: ${sample.title}`);
  } catch (error) {
    console.error(`Failed to add sample: ${sample.title}`, error);
  }
};

  const handleAddSamples = async () => {
    
    for (const sample of processedSamples) {
      await addSample(sample);
    }
  }

  return (
    <div className="samples-content">
      <div className="manage-card">
      <h3 className="card-title">{isEditing ? "Edit Sample" : "Add New Sample"}</h3>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Title
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              value={newSample.title}
              onChange={(e) =>
                setNewSample({ ...newSample, title: e.target.value })
              }
              className="form-control"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Open
          </Form.Label>
          <Col sm="10">
            <Form.Check
              type="switch"
              id="custom-switch"
              label={newSample.open === "inline" ? "Inline" : "External"}
              checked={newSample.open === "inline"}
              onChange={(e) =>
                setNewSample({
                  ...newSample,
                  open: e.target.checked ? "inline" : "external",
                })
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Type
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              value={newSample.type}
              onChange={(e) =>
                setNewSample({ ...newSample, type: e.target.value })
              }
              className="form-control"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Icon
          </Form.Label>
          {/* <Col sm="10">
            <Form.Control
              type="text"
              value={newSample.icon}
              onChange={(e) =>
                setNewSample({ ...newSample, icon: e.target.value })
              }
            />
          </Col> */}
          <Col sm="10">
            <Form.Control
              type="text"
              value={newSample.icon}
              onChange={(e) =>
                setNewSample({ ...newSample, icon: e.target.value })
              }
            />
            <Button onClick={() => setShowImageSelector(true)}>
              Select From Library
            </Button>
            {newSample.icon && (
              <img
                src={newSample.icon}
                alt="Selected Icon"
                className="selected-icon"
              />
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            URL
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              value={newSample.url}
              onChange={(e) =>
                setNewSample({ ...newSample, url: e.target.value })
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Category:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={newSample.CategoryID}
              onChange={(e) =>
                setNewSample({ ...newSample, CategoryID: e.target.value })
              }
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.CategoryID} value={category.CategoryID}>
                  {category.title}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Button onClick={handleAddOrUpdateSample}>
          {isEditing ? "Update Sample" : "Add Sample"}
        </Button>
        <Button onClick={handleCancelSample}>Cancel</Button>
      </Form>
      </div>
      <ImageSelectorPopup
        show={showImageSelector}
        handleClose={() => setShowImageSelector(false)}
        handleImageSelect={handleImageSelect}
        images={images}
      />

      <h4>Existing Samples</h4>
      {categories.map((category) => (
        <Card
          key={category.CategoryID}
          className="category-card"
          onDrop={(e) => onDrop(e, category.CategoryID)}
          onDragOver={(e) => e.preventDefault()}
        >
          <Card.Header>{category.title}</Card.Header>
          <Card.Body>
            {samples
              .filter((sample) => sample.CategoryID === category.CategoryID)
              .map((sample) => (
                <div
                  key={sample.SampleID}
                  draggable
                  onDragStart={(e) => onDragStart(e, sample.SampleID)}
                  className="sample-item"
                >
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + sample.icon}
                      alt={sample.title}
                      className="sample-icon"
                    />{" "}
                  </div>
                  <div>{sample.title}</div>
                  <div className="sample-btns">
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEditSample(sample)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteSample(sample.SampleID)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
          </Card.Body>
        </Card>
      ))}

      {uncategorizedSamples.length > 0 && (
        <Card className="category-card">
          <Card.Header>Uncategorized Samples</Card.Header>
          <Card.Body
            onDrop={(e) => onDrop(e, "")}
            onDragOver={(e) => e.preventDefault()}
          >
            {uncategorizedSamples.map((sample) => (
              <div
                key={sample.SampleID}
                draggable
                onDragStart={(e) => onDragStart(e, sample.SampleID)}
                className="sample-item"
              >
                <div>
                  <img
                    src={process.env.PUBLIC_URL + sample.icon}
                    alt={sample.title}
                    className="sample-icon"
                  />{" "}
                </div>
                <div>{sample.title}</div>
                <div className="sample-btns">
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => handleEditSample(sample)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteSample(sample.SampleID)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
      <button onClick={handleAddSamples} className="HideButton">Add Default samples</button>
    </div>
  );
};

export default Samples;
