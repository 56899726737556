import React, { useEffect, useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useDynamoDB } from "../DynamoDBContext";
import CategoryData from "../CategoryData";
import styles from "./HomePage.module.css";

const HomePage = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  

  const dynamoDB = useDynamoDB();
  // const categoryData = new CategoryData(dynamoDB);
  const categoryData = useMemo(() => new CategoryData(dynamoDB), [dynamoDB]);
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await categoryData.getCategories();
      setCategoriesData(data);
    };
    fetchData();
  }, [categoryData]);

  // Handle loading or error states, if required
  if (categoriesData.length === 0) {
    return <div className="loadingDiv">Loading...</div>;
  }

  return (
    <div className={styles.homePage}>
      {/* <h1 className={styles.title}>Digital Learning Samples</h1> */}
      <div className={styles.categoryGrid}>
        {categoriesData.map((category) =>
        
          category.CategoryID !== "contact" ? (
            <Link
              key={category.CategoryID}
              to={`/category/${category.CategoryID}`}
              className={styles.categoryLink}
            >
              <div className={styles.categoryCard}>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>
                <div className={styles.t_over}></div>

                <div className={styles.categoryCardBody}>
                  <div
                    className={styles.iconContainer}
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL + category.icon
                      })`,
                    }}
                    aria-label={category.title}
                  >
                    {/* <div className={styles.iconContainer}> */}
                    {/* <img src={process.env.PUBLIC_URL + category.icon} alt={category.title} className={styles.icon} /> */}
                  </div>
                  <div className={styles.icon_label_container}>
                    <h2 className={styles.label}>{category.title}</h2>
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            // </Link>
            <Link
              key={category.CategoryID}
              to={`/category/${category.CategoryID}`}
              className={styles.categoryLink}
            ></Link>
          )
        )}
      </div>
    </div>
  );
};

export default HomePage;