import React, { useEffect, useRef, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Divide as Hamburger } from "hamburger-react";
import { useDynamoDB } from "../DynamoDBContext";
import CategoryData from "../CategoryData";
import ImageModal from './ImageModal';
import VideoModal from './VideoModal';
import styles from "./CategoryPage.module.css";

const CategoryPage = () => {
  const dynamoDB = useDynamoDB();
  // const categoryData = new CategoryData(dynamoDB);
  const categoryData = useMemo(() => new CategoryData(dynamoDB), [dynamoDB]);

  const { categoryId } = useParams();
  const [isOpen, setOpen] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const menuRef = useRef(null);
  const history = useHistory();
  const [initialLoad, setInitialLoad] = useState(true);
  const [isContactsPage, setContactsPage] = useState(false);

  const [categories, setCategoriesData] = useState([]);
  const [categoryOptions, setSelectedCategoryOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageTitle, setImageTitle] = useState('');
  const [showVideoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');

  const openModal = (imageUrl, imageTitle) => {
    setSelectedImage(imageUrl);
    setImageTitle(imageTitle);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage('');
    setImageTitle('');
    setIsModalOpen(false);
  };

  const openVideoPopup = (videoSrc) => {
    setSelectedVideo(videoSrc);
    setVideoModalOpen(true);
  };

  const closeVideoPopup = () => {
    setSelectedVideo('');
    setVideoModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await categoryData.getCategories();
      setCategoriesData(data);

      const options = await categoryData.getCategoryOptions(categoryId);
      setSelectedCategoryOptions(options);
    };
    fetchData();

    setFadeIn(true);
    if (menuRef.current) {
      if (initialLoad) {
        setInitialLoad(false);
        menuRef.current.classList.add(styles.menuVisible);
      }
    }
  }, [categoryData, categoryId, initialLoad]);

  useEffect(() => {
    if (categoryOptions.length) {
      if (categoryOptions[0].title === "contact_info") {
        setContactsPage(true);
      } else {
        setContactsPage(false);
      }
    }
  }, [categoryOptions]);

  if (!categories.length) {
    return <div className="loadingDiv">Loading...</div>;
  }

  const handleMenuItemClick = (category) => {
    if (category.CategoryID !== categoryId) {
      menuRef.current.classList.add(styles.menuVisible);
      history.push(`/category/${category.CategoryID}`);
    }
  };

  const handleCategoryClick = () => {
    history.push(`/category/contact`);
  };

  const handleIconClick = (url, title, open, type) => {
    if (open) {
      if (open === "inline") {
        let open_link_type = "image";
        if (type) {
          if (type === "image") {
            open_link_type = "image";
          }
          if (type === "video") {
            open_link_type = "video";
          }
        }

        switch (open_link_type) {
          case "image":
            openModal(url, title);
            break;
          case "video":
            openVideoPopup(url, title);
            break;
          default:
            break;
        }
        
      } else {
        window.open(url, "_blank");
      }
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <div className={styles.categoryPage}>
      <div className={[styles.menu_bar]}>
        <div className={[styles.category_title]}>
          {categories.map((category) =>
            category.CategoryID === categoryId ? category.title : ""
          )}
        </div>
        <div className={[styles.category_menu_icon]}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
      </div>
      <div
        className={`${styles.menu} ${isOpen ? styles.showMenu : ""}`}
        ref={menuRef}
      >
        {categories.map((category) =>
          category.CategoryID !== "contact" ? (
            <div
              key={category.CategoryID}
              className={`${styles.menuItem} ${
                category.CategoryID === categoryId ? styles.selected : ""
              }`}
              onClick={() => handleMenuItemClick(category)}
            >
              {category.title}
            </div>
          ) : (
            ""
          )
        )}
        <div className={styles.contact_container}>
          <div
            className={`${styles.contact} ${
              categoryId === "contact" ? styles.selected : ""
            }`}
            onClick={handleCategoryClick}
          >
            Contact Information
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div
          className={`${styles.optionList} ${
            isContactsPage ? styles.contacts_page : ""
          }`}
        >
          {categoryId === "contact" ? (
            <div className={styles.contact_text_container}>
              <div
                className={styles.contact_sec + " " + styles.contact_title}
              >
                <span className={styles.vp_name}>Raman Arora</span>
                <span>Vice President, Ed-Tech & Digital Learning</span>
              </div>
              <div
                className={styles.contact_sec + " " + styles.contact_tel}
              >
                <div
                  className={styles.contact_icon}
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      "/images/icons/phone_icon.svg"
                    })`,
                  }}
                  title="Telephone"
                ></div>
                <a href="tel:+1703342944" className={styles.txt_link}>
                  +1.703.342.944
                </a>
              </div>
              <div
                className={styles.contact_sec + " " + styles.contact_email}
              >
                <div
                  className={styles.contact_icon}
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      "/images/icons/email_icon.svg"
                    })`,
                  }}
                  title="Email"
                ></div>
                <a
                  href="mailto:raman.arora@aptaracorp.com?subject=Hey!"
                  className={styles.txt_link}
                >
                  Raman.Arora@aptaracorp.com
                </a>
              </div>
              <div
                className={styles.contact_sec + " " + styles.contact_loc}
              >
                <div
                  className={styles.contact_icon}
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      "/images/icons/location_icon.svg"
                    })`,
                  }}
                  title="Location"
                ></div>
                4501 Daly Drive, Suite 312,
                <br />
                Chantilly VA 20151
              </div>
              <div
                className={styles.contact_sec + " " + styles.contact_url}
              >
                <div
                  className={styles.contact_icon}
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "/images/icons/url_icon.svg"
                    })`,
                  }}
                  title="Website"
                ></div>
                <a
                  href="https://www.aptaracorp.com/why-aptara/our-clients/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.txt_link}
                >
                  Our Clients
                </a>
              </div>
            </div>
          ) : (
            categoryOptions.length > 0 ? (
              categoryOptions.map((option, index) =>
                option.title !== "contact_info" ? (
                  <div
                    key={"opt_" + option.SampleID}
                    className={`${styles.optionCard} ${
                      fadeIn ? styles.fadeIn : ""
                    }`}
                    style={{ animationDelay: `${(index + 1) * 0.1}s` }}
                    onClick={() =>
                      handleIconClick(option.url, option.title, option.open, option.type)
                    }
                  >
                    <div
                      className={styles.icon_image}
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL + option.icon
                        })`,
                      }}
                      aria-label={option.title}
                    ></div>
                    <div className={styles.icon_label_container}>
                      <h2 className={styles.label}>{option.title}</h2>
                    </div>
                  </div>
                ) : (
                  <div key={"opt_" + option.SampleID}>
                    <div className={styles.contact_text_container}>
                      <div
                        className={styles.contact_sec + " " + styles.contact_title}
                      >
                        <span className={styles.vp_name}>Raman Arora</span>
                        <span>Vice President, Ed-Tech & Digital Learning</span>
                      </div>
                      <div
                        className={styles.contact_sec + " " + styles.contact_tel}
                      >
                        <div
                          className={styles.contact_icon}
                          style={{
                            backgroundImage: `url(${
                              process.env.PUBLIC_URL +
                              "/images/icons/phone_icon.svg"
                            })`,
                          }}
                          title="Telephone"
                        ></div>
                        <a
                          href="tel:+1703342944"
                          className={styles.txt_link}
                        >
                          +1.703.342.944
                        </a>
                      </div>
                      <div
                        className={styles.contact_sec + " " + styles.contact_email}
                      >
                        <div
                          className={styles.contact_icon}
                          style={{
                            backgroundImage: `url(${
                              process.env.PUBLIC_URL +
                              "/images/icons/email_icon.svg"
                            })`,
                          }}
                          title="Email"
                        ></div>
                        <a
                          href="mailto:raman.arora@aptaracorp.com?subject=Hey!"
                          className={styles.txt_link}
                        >
                          Raman.Arora@aptaracorp.com
                        </a>
                      </div>
                      <div
                        className={styles.contact_sec + " " + styles.contact_loc}
                      >
                        <div
                          className={styles.contact_icon}
                          style={{
                            backgroundImage: `url(${
                              process.env.PUBLIC_URL +
                              "/images/icons/location_icon.svg"
                            })`,
                          }}
                          title="Location"
                        ></div>
                        4501 Daly Drive, Suite 312,
                        <br />
                        Chantilly VA 20151
                      </div>
                      <div
                        className={styles.contact_sec + " " + styles.contact_url}
                      >
                        <div
                          className={styles.contact_icon}
                          style={{
                            backgroundImage: `url(${
                              process.env.PUBLIC_URL + "/images/icons/url_icon.svg"
                            })`,
                          }}
                          title="Website"
                        ></div>
                        <a
                          href="https://www.aptaracorp.com/why-aptara/our-clients/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.txt_link}
                        >
                          Our Clients
                        </a>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div>No samples available in this category.</div>
            )
          )}
        </div>
      </div>
      {isModalOpen && (
        <ImageModal imageUrl={selectedImage} imageTitle={selectedImageTitle} onClose={closeModal} />
      )}
      {showVideoModalOpen && (
        <VideoModal videoSrc={selectedVideo} onClose={closeVideoPopup} />
      )}
    </div>
  );
};

export default CategoryPage;
