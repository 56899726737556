import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useDynamoDB } from "../../DynamoDBContext";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const Categories = () => {
  const dynamoDB = useDynamoDB();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ title: "", icon: "" });
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Fetch categories and set initial sequence if not present
  const fetchCategories = useCallback(async () => {
    const params = { TableName: "Categories" };
    const data = await dynamoDB.scan(params).promise();

    // Set initial sequence if not present
    const sortedCategories = data.Items.sort((a, b) => a.sequence - b.sequence);
    let updatedCategories = [];
    for (let i = 0; i < sortedCategories.length; i++) {
      if (typeof sortedCategories[i].sequence === "undefined") {
        sortedCategories[i].sequence = i;
        const updateParams = {
          TableName: "Categories",
          Key: { CategoryID: sortedCategories[i].CategoryID },
          UpdateExpression: "set #sequence = :sequence",
          ExpressionAttributeNames: { "#sequence": "sequence" },
          ExpressionAttributeValues: { ":sequence": i },
        };
        await dynamoDB.update(updateParams).promise();
      }
      updatedCategories.push(sortedCategories[i]);
    }

    setCategories(updatedCategories);
  }, [dynamoDB]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleAddCategory = async () => {
    const params = {
      TableName: "Categories",
      Item: {
        ...newCategory,
        CategoryID: Date.now().toString(),
        sequence: categories.length, // Add new category at the end
      },
    };
    await dynamoDB.put(params).promise();
    fetchCategories();
    setNewCategory({ title: "", icon: "" });
  };

  const handleDeleteCategory = async (CategoryID) => {
    const params = {
      TableName: "Categories",
      Key: { CategoryID },
    };
    await dynamoDB.delete(params).promise();
    setCategories(categories.filter((cat) => cat.CategoryID !== CategoryID));
  };

  const handleUpdateCategory = async () => {
    if (!selectedCategory) return;

    const updatedCategory = {
      ...selectedCategory,
      title: newCategory.title || selectedCategory.title,
      icon: newCategory.icon || selectedCategory.icon,
    };

    const params = {
      TableName: "Categories",
      Item: updatedCategory,
    };
    await dynamoDB.put(params).promise();
    setCategories(
      categories.map((cat) =>
        cat.CategoryID === selectedCategory.CategoryID ? updatedCategory : cat
      )
    );
    setSelectedCategory(null);
    setNewCategory("");
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setNewCategory({ title: category.title, icon: category.icon });
  };

  // Handle drag and drop, and update sequence in the database
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCategories(items);

    // Update sequence in DynamoDB
    for (let i = 0; i < items.length; i++) {
      if (items[i].sequence !== i) {
        // Only update if the sequence has changed
        const params = {
          TableName: "Categories",
          Key: { CategoryID: items[i].CategoryID },
          UpdateExpression: "set #sequence = :sequence",
          ExpressionAttributeNames: { "#sequence": "sequence" },
          ExpressionAttributeValues: { ":sequence": i },
        };
        await dynamoDB.update(params).promise();
      }
    }
  };
  const isButtonDisabled = !newCategory.title.trim();
  return (
    <div className="categories-content">
      <div className="manage-card">
        <h3 className="card-title">Manage Categories</h3>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm="3" className="form-label">
              Title
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Enter category title"
                value={newCategory.title}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, title: e.target.value })
                }
                className="form-control"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3" className="form-label">
              Icon URL
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Enter icon URL"
                value={newCategory.icon}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, icon: e.target.value })
                }
                className="form-control"
              />
            </Col>
          </Form.Group>
          <div className="button-group">
            {selectedCategory ? (
              <Button
                variant="primary"
                onClick={handleUpdateCategory}
                className="btn-primary"
                disabled={isButtonDisabled}
              >
                Update Category
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={handleAddCategory}
                className="btn-primary"
                disabled={isButtonDisabled}
              >
                Add Category
              </Button>
            )}
          </div>
        </Form>
      </div>

      <h3 className="card-title">Existing Categories</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <ul
              className="list-group"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {categories.map((category, index) => (
                <Draggable
                  key={category.CategoryID}
                  draggableId={category.CategoryID}
                  index={index}
                >
                  {(provided) => (
                    <li
                      className="list-group-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <span>
                        <img
                          src={process.env.PUBLIC_URL + category.icon}
                          alt={category.title}
                          className="category-icon mr-2"
                        />{" "}
                      </span>
                      <span className="list-item-title">{category.title}</span>
                      <span className="list-item-btns">
                        <button onClick={() => handleEditCategory(category)}>
                          Edit
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteCategory(category.CategoryID)
                          }
                        >
                          Delete
                        </button>
                      </span>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Categories;
