import React, { useState } from "react";
import "./LoginForm.css";

const LoginForm = ({ onLoginClick, loginError, loginErrorMsg}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onLoginClick(email, password);
  };

  return (
    <div className="login-form-container">
      
      <div className="login-form">
        
        <div className="login-section">
          <div className="bottom_section">
            <form className="form-section" onSubmit={handleFormSubmit}>
              <img src="../../images/aptara_logo_2x.png" className="logo aptLogo" alt="Aptara logo"/>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  id="email"
                  placeholder="User ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {loginError && (
                <div className="error-message">
                  {loginErrorMsg}
                </div>
              )}
              <button type="submit">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
