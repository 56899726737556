import React, { createContext, useContext } from 'react';
import AWS from 'aws-sdk';

// Create the context
const DynamoDBContext = createContext();

// Create a provider component
export const DynamoDBProvider = ({ children }) => {
  // Configure AWS SDK
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const dynamoDB = new AWS.DynamoDB.DocumentClient();

  return (
    <DynamoDBContext.Provider value={dynamoDB}>
      {children}
    </DynamoDBContext.Provider>
  );
};

// Create a custom hook to use the DynamoDB context
export const useDynamoDB = () => {
  return useContext(DynamoDBContext);
};
