import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

const Header = () => {
  const imagePath = '/images/aptaracorp-logo-1.png';
  const openMainSite = () => {
    const externalSiteUrl = 'https://www.aptaracorp.com/why-aptara/our-clients/';
    window.open(externalSiteUrl, '_blank');
  }
  return (
    <header className={styles.header}>
      <Link to="/"><h1 className={styles.title}>Digital Learning Samples</h1></Link>
      <div className={styles.logo}>
      
      <div onClick={openMainSite}><img src={process.env.PUBLIC_URL + imagePath} alt="Aptara Logo" /></div>
      </div>
      {/* <nav>
        <ul className={styles.navLinks}>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </nav> */}
    </header>
  );
}

export default Header;
