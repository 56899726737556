import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query, 
  where,
  getDocs,
  addDoc,
  doc, deleteDoc, updateDoc
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAcLTvyvpPhpxy6S9vrgw5vQ-x6voHY2TQ",
  authDomain: "assessment-create.firebaseapp.com",
  projectId: "assessment-create",
  storageBucket: "assessment-create.appspot.com",
  messagingSenderId: "339883510843",
  appId: "1:339883510843:web:6416dfe2821d7c7ebd601e",
  measurementId: "G-JYVRHG2SQT",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();

const FirebaseService = {
  handleSignIn: async (email, password) => {
    
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      
      return {
        login: "success",
        email: email,
        uid: userCredential.user.uid,
      };
      // .then((user) => {
      // //   setUserUid(auth.currentUser.uid);
      // //   setLoginError(false);
      // //   setLoggedIn(true);
      //     return {
      //         "login":"success",
      //         "email": email,
      //         "uid": auth.currentUser.uid
      //     }
      // })
      // .catch((e) => {
      // //   setLoginError(true);
      //     console.log("Sign failed!", e);
      //     return {
      //         "login":"failed"
      //     }
      // });
      // Additional logic after successful sign-in
    } catch (error) {
      
        // Catch the specific error and re-throw a custom error
      if (error.code === 'auth/user-not-found') {
        // throw new Error('User not found. Please check your email and password.');
        return {
            login: "failed",
            error: 'User not found. Please check your email and password.'
          };
      } else if (error.code === 'auth/wrong-password') {
        // throw new Error('Invalid password. Please check your email and password.');
        return {
            login: "failed",
            error: 'Invalid password. Please check your email and password.'
          };
      } else {
        // throw new Error('An error occurred during sign-in. Please try again later.');
        return {
            login: "failed",
            error: 'An error occurred during sign-in. Please try again later.'
          };
      }
      //   throw new Error(error.message);
    }
  },

  handleSignOut: async (e) => {
    e.preventDefault();
    try {
      await auth.signOut();
      return {
        signout: "success",
      };
    } catch (error) {
      console.error("Sign out failed:", error);
      throw new Error(error.message);
    }
  },

  saveInteractionToDatabase: async (interaction) => {
    const firestore = getFirestore(firebaseApp);

    // const user_data_doc = doc(firestore, "user_data/interactions_data");
    const user_data_col = collection(firestore, "user_data");

    addDoc(user_data_col, interaction)
      .then(() => {
        console.log(`Interaction saved to DB`);
      })
      .catch((error) => {
        console.log(`Error saving interaction data to DB ${error}`);
      });
  },

  addUserPrompt: async (user_id, user_prompt, prompt_access) => {
    const db = getFirestore();
    const promptsCollection = collection(db, "user_promots");

    try {
      // Check if a prompt with the same value already exists
      const q = query(promptsCollection, 
        where("user_prompt", "==", user_prompt),
        where("user_id", "==", user_id)
      );

      const querySnapshot = await getDocs(q);
    
      if (!querySnapshot.empty) {
        // Prompt with the same value already exists
        console.log("Prompt already exists:", user_prompt);
        return null; // Return null or a specific message indicating the prompt exists
      }

      // Add the new prompt as it doesn't already exist
      const docRef = await addDoc(promptsCollection, { user_id, user_prompt, prompt_access });
      return docRef.id; // Return the ID of the newly added prompt
    } catch (error) {
      console.error("Error adding prompt:", error);
      throw error; // Handle the error as needed
    }
  },
  deleteUserPrompt: async (promptId) => {
    const db = getFirestore();
    const promptDoc = doc(db, "user_promots", promptId); // Reference to the document to delete
    
    try {
      // Delete the document
      await deleteDoc(promptDoc);
      return promptId; // Return the ID of the deleted prompt
    } catch (error) {
      console.error("Error deleting prompt:", error); // Optionally, handle the error more gracefully here
      throw error; // Rethrow the error to be handled by the calling function
    }
  },
  fetchPrompts: async (promptType, userUid) => {
    const db = getFirestore();
    let promptsCollection;
    if (promptType === "global")
    {
      promptsCollection = collection(db, "global_prompts");
    }else if (promptType === "user"){
      promptsCollection = query(collection(db, "user_promots"), where('user_id', '==', userUid));
      // promptsCollection = collection(db, "user_promots");
    }
    

    try {
      const querySnapshot = await getDocs(promptsCollection);
      const prompts = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        prompts.push({ id: doc.id, ...data });
      });

      return prompts;
    } catch (error) {
      console.error("Error fetching prompts:", error);
      throw error;
    }
  },
  editUserPrompt: async (promptId, newPromptValue) => {
    const db = getFirestore();
    const promptDoc = doc(db, "user_promots", promptId); // Reference to the specific document
  
    try {
      // Update the user_prompt field with the new value
      await updateDoc(promptDoc, {
        user_prompt: newPromptValue,
      });
      return promptId; // Return the ID of the updated prompt
    } catch (error) {
      console.error("Error updating prompt:", error);
      throw error; // Handle the error as needed
    }
  }
};

export default FirebaseService;
