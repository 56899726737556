import React, { useState } from "react";
import FirebaseService from "../FirebaseService";

import LoginForm from "./admincomponents/LoginForm";

import Categories from "./admincomponents/Categories";
import Samples from "./admincomponents/Samples";
import Clients from "./admincomponents/Clients";

import "./Admin.css";

const Admin = () => {
  const [isLoggedin, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setloginErrorMsg] = useState(
    "Invalid email or password. Please try again."
  );
  // const [userEmail, setUserEmail] = useState(null);
  // const [userUid, setUserUid] = useState(null);

  const [activeTab, setActiveTab] = useState("categories");

  const handleSignIn = async (email, password) => {
    const returnObj = await FirebaseService.handleSignIn(email, password);
    if (returnObj.login === "success") {
      // setUserEmail(returnObj.email);
      // setUserUid(returnObj.uid);
      setLoginError(false);
      setLoggedIn(true);
    } else {
      setloginErrorMsg(returnObj.error);
      setLoginError(true);
      setLoggedIn(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "categories":
        return <Categories />;
      case "samples":
        return <Samples />;
      case "clients":
        return <Clients />;
      default:
        return null;
    }
  };



  

  return (
    <div>
      {!isLoggedin ? (
        <div className="login_page displayPage">
          <LoginForm
            onLoginClick={handleSignIn}
            loginError={loginError}
            loginErrorMsg={loginErrorMsg}
          />
        </div>
      ) : (
      <div className="admin-container">
        <div className="tabs">
          <button
            className={activeTab === "categories" ? "active" : ""}
            onClick={() => setActiveTab("categories")}
          >
            Categories
          </button>
          <button
            className={activeTab === "samples" ? "active" : ""}
            onClick={() => setActiveTab("samples")}
          >
            Samples
          </button>
          <button
            className={activeTab === "clients" ? "active" : ""}
            onClick={() => setActiveTab("clients")}
          >
            Clients
          </button>
        </div>
        <div className="tab-content">{renderTabContent()}</div>
      </div>
      )}
      
    </div>
  );
};

export default Admin;
