import React, { useRef, useEffect } from 'react';
import './VideoModal.css';

const VideoModal = ({ videoSrc, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    // Function to close the modal when clicking outside of it
    const handleCloseModal = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add a click event listener to the document
    document.addEventListener('mousedown', handleCloseModal);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleCloseModal);
    };
  }, [onClose]);
  return (
    <div className="video-modal-container">
      <div className="video-modal" ref={modalRef}>
        <button className="close-button" onClick={onClose} alt="Close" title="Close"></button>
        <video controls controlsList="nodownload"> {/* Add controlsList */}
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoModal;
