import React, { useState } from "react";
import axios from 'axios';
import './ImageSelectorPopup.css';

const ImageSelectorPopup = ({ show, handleClose, handleImageSelect, images }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        // const res = await axios.post('http://localhost:5200/upload', formData, {
        await axios.post('http://localhost:5200/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // handleImageSelect(`/static/media/${res.data.filename}`);
        // checkUploadComplete(res.data.filename);
        setSelectedFile(null);
        setPreview(null);
        // handleClose();
      } catch (err) {
        console.error("Error uploading file:", err);
      }
    }
  };

  // const checkUploadComplete = (filename) => {
  //   console.log("Look for: ",filename)
  //   images.map((image, index) => (
  //     console.log(image)
  //   ))
  // }

  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h3>Select an Icon or Upload a New File</h3>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </div>

        <div className="image-grid">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`icon-${index}`}
              onClick={() => handleImageSelect(image)}
              className="image-grid-item"
            />
          ))}
        </div>

        <div className="upload-section">
          <input type="file" accept="image/*,video/*" onChange={handleFileChange} />
          {preview && (
            <div className="preview-section">
              {selectedFile.type.startsWith("video/") ? (
                <video width="100%" controls>
                  <source src={preview} type={selectedFile.type} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={preview} alt="Preview" className="preview-image" />
              )}
            </div>
          )}
        </div>

        <div className="popup-footer">
          <button onClick={handleUpload} disabled={!selectedFile}>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageSelectorPopup;
