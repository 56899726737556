import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import HomePage from './components/HomePage';
import CategoryPage from './components/CategoryPage';
import Admin from './components/Admin';
import Header from './components/Header';
import { DynamoDBProvider } from './DynamoDBContext';
import './App.css';

const App = () => {
  const basename = process.env.NODE_ENV === 'production' ? '/DigitalSamplesShowcase/showcase/' : '/';
  return (
    <DynamoDBProvider>
      <Router basename={basename}>
        <Header />
        <Route
          render={({ location }) => (
              <CSSTransition key={location.key} classNames="page-transition" timeout={300}>
                <Switch location={location}>
                  <Route exact path="/" component={HomePage} />
                  <Route path="/category/:categoryId" component={CategoryPage} />
                  <Route path="/admin" component={Admin} />
                </Switch>
              </CSSTransition>
          )}
        />
      </Router>
    </DynamoDBProvider>
  );
};

export default App;